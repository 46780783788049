import React from "react"
import ScrollToTop from "react-scroll-up"
import {Button} from "@mui/material"
import {ArrowUp} from "react-feather"
import classnames from "classnames"
import CookieC from "../../constants/cookie.c";
import {GlobalContext} from "../../utility/Global";
import {FormattedMessage} from "react-intl";

const Footer = props => {
  let footerTypeArr = ["sticky", "static", "hidden"]
  return (
    <GlobalContext.Consumer>
      {context => (
        <footer
          className={classnames("footer footer-light", {
            "footer-static": true,
            "d-none": false
          })}
        >
          <div className="mb-0 clearfix d-flex">
            <div className="float-md-left d-block d-md-inline-block mt-25 col px-0">
              COPYRIGHT © IUC {new Date().getFullYear()}&nbsp;&nbsp; <FormattedMessage id='All rights reserved'/>
            </div>
            <div className='d-flex col-auto align-items-center'>
              Version :<Button
              style={{paddingLeft: 4, paddingRight: 4}}
              onClick={() => context.setState({openFeature: true})}>{CookieC.VERSION}</Button></div>
          
          </div>
            <ScrollToTop showUnder={160}>
              <Button color="primary" className="btn-icon scroll-top">
                <ArrowUp size={15}/>
              </Button>
            </ScrollToTop>
        </footer>
      )}
    </GlobalContext.Consumer>
  )
}

export default Footer
