import React from "react"
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap"
import * as Icon from "react-feather"
import ReactCountryFlag from "react-country-flag"
import {IntlContext} from "../../../utility/Internationalization"
import CookieH from "../../../helpers/cookie.h";
import {GlobalContext} from "../../../utility/Global";
import userDefault from './../../../assets/img/pages/user-default.jpg'
import {FormattedMessage} from "react-intl";
import {Divider} from "@mui/material";

const UserDropdown = () => {
  return (
    <GlobalContext.Consumer>
      {context => {
        return (
          <DropdownMenu end>
            <DropdownItem
              onClick={e => {
                e.preventDefault()
                context.setState({activatedDark: !context.state.activatedDark})
              }}
            >
              {context.state.activatedDark ? <Icon.Sun size={14} className="mr-50"/> :
                <Icon.Moon size={14} className="mr-50"/>}
              <span className="align-middle">
                              {context.state.activatedDark ? <FormattedMessage id='Light mode'/> :
                                <FormattedMessage id='Dark mode'/>}
              </span>
            </DropdownItem>
            <Divider/>
            <DropdownItem
              onClick={e => {
                e.preventDefault()
                if (CookieH.getUser()) {
                  CookieH.clearUser()
                  context.setState({})
                  window.location = '/login'
                }
              }}
            >
              <Icon.Power size={14} className="mr-50"/>
              <span className="align-middle">Log Out</span>
            </DropdownItem>
          </DropdownMenu>
        )
      }}
    </GlobalContext.Consumer>
  )
}

class NavbarUser extends React.PureComponent {

  state = {
    langDropdown: false,
  }

  componentDidMount() {
    if (!Boolean(CookieH.getUser())) {
      window.location = ('/login')
    }
  }

  handleLangDropdown = () =>
    this.setState({langDropdown: !this.state.langDropdown})

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <IntlContext.Consumer>
          {context => {
            let langArr = {
              "en": "English",
              "fr": "French",
            }
            return (
              <Dropdown
                tag="li"
                className="dropdown-language nav-item"
                isOpen={this.state.langDropdown}
                toggle={this.handleLangDropdown}
                data-tour="language"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link"
                >
                  <ReactCountryFlag
                    className="country-flag"
                    countryCode={
                      context.state.locale === "en"
                        ? "us"
                        : context.state.locale
                    }
                    svg
                  />
                  <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
                    {langArr[context.state.locale]}
                  </span>
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("en")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="us" svg/>
                    <span className="ml-1">English</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("fr")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="fr" svg/>
                    <span className="ml-1">French</span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )
          }}
        </IntlContext.Consumer>

        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link h-100">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {CookieH.getUser()?.USERFULLNAME}
              </span>
              <span className="user-status">
                {CookieH.getUser()?.USERPROFILE}
              </span>
            </div>
            <span data-tour="user h-100">
              <img
                src={userDefault}
                className="round h-100"
                height="40"
                width="40"
                alt="default user"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} cookies={this.cookies}/>
        </UncontrolledDropdown>
      </ul>
    )
  }
}

export default NavbarUser
