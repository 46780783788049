import React, {createContext} from "react";
import EmployeesService from "../services/employee.s";
import EmployeeS from "../services/employee.s";
import CookieH from "../helpers/cookie.h";
import DepartmentsService from "../services/department.s";
import DepartmentS from "../services/department.s";
import DiplomaService from "../services/dilploma.s";
import DilplomaS from "../services/dilploma.s";
import {blobToBase64} from "base64-blob";
import {withRouter} from 'react-router-dom';
import {FormattedMessage} from "react-intl";
import MySnackbar from "../components/mySnackbar";
import ClassesS from "../services/classes.s";
import UserS from "../services/user.s";
import ProfileS from "../services/profile.s";
import RequestGroupS from "../services/requestGroup.s";
import {history} from "../history";
import RequestS from "../services/request.s";
import IdTypesS from "../services/idTypes.s";
import ApplicationChoiceS from "../services/applicationChoice.s";
import ApplicationS from "../services/application.s";
import SessionApplicationS from "../services/sessionApplication.s";
import _ from "lodash";
import SessionExpired from "../layouts/components/sessionExpired";
import RequestBatchS from "../services/requestBatch.s";
import CookieC from "../constants/cookie.c";
import ReqCategoriesS from "../services/reqCategories.s";
import LibeleDecisionS from "../services/libeleDecision.s";
import EvaluationSessionS from "../services/evaluationSession.s";
import SubjectsS from "../services/subjects.s";
import EvaluationS from "../services/evaluation.s";
import EvaluationFormS from "../services/evaluationForm.s";
import QuestionFormS from "../services/questionForm.s";
import MsgDialog from "../components/msgDialog";
import {Backdrop, CircularProgress} from "@mui/material";
import AcademicYearS from "../services/academicYear.s";

const Context = createContext(null);

class Global extends React.Component {

  initialState = {
    activatedDark: false,
    administrators: [],
    batches: [],
    classes: [],
    employees: [],
    exclusions: [],
    groups: [],
    indexEmp: 1,
    openDg: false,
    organizations: [],
    request: [],
    requestParsed: [],
    requestReport: [],
    roles: [],
    sessionApp: [],
    sessionAppFilter: [],
    sessionSubjectClass: [],
    sessionSubjectClass2: [],
    sessions: [],
    sidebar: false,
    years: [],
  }

  state = {
    ...this.initialState
  }


  loadRoles = () => {
    try {
      new ProfileS().getProfiles().then(res => {
        this.setState({roles: res.data})
      }, err => {
        if (err?.status === 401)
          return this.tokenExpiredRedirection()
      })
    } catch (e) {
      console.log(e)
    }
  }
  addRole = (role, privilege, editing, id) => {
    if (editing)
      return new ProfileS().updateRole({role, privilege, id}).then(res => {
        if (res.status === 200) {
          this.loadRoles()
          this.setState({
            openSb: true,
            severitySb: 'success',
            messageSb: 'Mise à jour du profil realisé avec succes'
          })
          return res
        }
      }, err => {
        if (err?.status === 401)
          return this.tokenExpiredRedirection()
        return err
      })
    else
      return new ProfileS().addRole({role, privilege}).then(res => {
        if (res.status === 201) {
          this.loadRoles()
          this.setState({
            openSb: true,
            severitySb: 'success',
            messageSb: 'Creation du profil realisé avec succes'
          })
          return res
        }
      }, err => {
        if (err?.status === 401)
          return this.tokenExpiredRedirection()
        return err
      })
  }

  loadRequestGroups = () => {
    new RequestGroupS().getRequestGroup().then(res => {
      this.setState({groups: res.data})
    }, err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
    })
  }

  loadSessionApplications = (sessionId) => {
    this.setState({sessionApp: []})
    new ApplicationS().getForSession(sessionId).then(res => {
      if (res.status === 200)
        this.setState({sessionApp: res.data})
    }, err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
    })
  }
  loadSessionApplicationsFilter = (sessionId) => {
    this.setState({sessionAppFilter: []})
    new ApplicationS().getForSessionAffectionList(sessionId).then(res => {
      if (res.status === 200)
        this.setState({sessionAppFilter: res.data})
    }, err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
    })
  }
  loadSessionSubjectChoiceForClass = (organization) => {
    new ApplicationChoiceS().getAppChoiceForSubject(organization).then(res => {
      this.setState({
        sessionSubjectClass: _.uniqBy(res.data.map((e, i, arr) => ({
          ...e.Subject,
          ok: arr.filter(a => e.SUBJECT_ID === a.SUBJECT_ID && a.AFFECTATION !== -1).length > 0
        })), 'SUBJECT_ID') || []
      })
    }, err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
    })
  }
  loadSessionSubjectChoiceForClass2 = (organization) => {
    new ApplicationChoiceS().getAppChoiceForSubject2(organization).then(res => {
      this.setState({sessionSubjectClass2: res.data || []})
    }, err => {
      // if (err?.status === 401)
      //   return this.tokenExpiredRedirection()
    })
  }
  reOpenSession = (value, sessionId) => {
    new SessionApplicationS().setActived({id: sessionId, value}).then(res => {
      if (res.status === 200)
        this.loadSessionApplications(sessionId)
    }, err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
    })
  }

  updateStatus = (STATUS) => {
    return new EmployeesService().updateStatus({
      STATUS,
      MATRICULE: CookieH.getUser().MATRICULE
    }).then((res) => {
      if (res.status === 200) {
        this.setState({
          STATUS,
          severitySb: 'success',
          messageSb: <FormattedMessage id="Successly saved"/>,
          openSb: true
        })
        CookieH.setUser(this.state)
      }
    }, err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
    })
  }
  updatePersonal = () => {
    return new EmployeesService().updatePersonnal({
      ...this.state
    }).then(res => {
      if (res.status === 200) {
        CookieH.setUser(this.state)
        this.setState({
          severitySb: 'success',
          messageSb: <FormattedMessage id="Successly saved"/>,
          openSb: true,
          submitePerso: true
        })
      }
    }, (err) => {
      this.setState({
        severitySb: 'error',
        messageSb: "Une erreur s'est produite lors de la tentative de sauvegarde",
        openSb: true
      })
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
    })
  }
  updateFamilial = () => {
    if (!this.state.EMERGNUM1 || !this.state.CIVILSTATUS ||
      !this.state.CHILDNUM || !this.state.EMERGNAME1) {
      this.setState({
        severitySb: 'warning',
        messageSb: "Veuillez renseigner toutes les informations avant de continuer",
        openSb: true
      })
      return
    }

    if (!this.state.EMERGNUM1.match('^6(2|5|6|7|8|9)[0-9]{7,}')) {
      this.setState({
        severitySb: 'warning',
        messageSb: "Le format de numéro de téléphone est incorrect",
        openSb: true
      })
      return
    }

    if (this.state.CIVILSTATUS === 'Married' && (
      (!this.state.SPLASTNAME || this.state.SPLASTNAME.length === 0) ||
      (!this.state.SPFIRSTNAME || this.state.SPFIRSTNAME.length === 0))
    ) {
      this.setState({
        severitySb: 'warning',
        messageSb: "Veuillez renseigner les informations sur votre du conjoint(e) pour continuer",
        openSb: true
      })
      return
    }

    new EmployeesService().updateFamilial({
      ...this.state
    }).then(res => {
      if (res.status === 200) {
        CookieH.setUser(this.state)
        this.setState({
          severitySb: 'success',
          messageSb: <FormattedMessage id="Successly saved"/>,
          openSb: true,
          submiteFamily: true
        })
      }
    }, (err) => {

      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      this.setState({
        severitySb: 'error',
        messageSb: "Une erreur s'est produite lors de la tentative de sauvegarde",
        openSb: true
      })
    })
  }
  updateAddress = () => {
    if (!this.state.NUMPHONE.match('^6(2|5|6|7|8|9)[0-9]{7,}')) {
      this.setState({
        severitySb: 'warning',
        messageSb: "Le format du numéro de téléphone 1 est incorrect",
        openSb: true
      })
      return
    }


    if (this.state.NUMPHONE2 && !this.state.NUMPHONE2.match('^6(2|5|6|7|8|9)[0-9]{7,}')) {
      this.setState({
        severitySb: 'warning',
        messageSb: "Le format du numéro de téléphone 2 est incorrect",
        openSb: true
      })
      return
    }

    if (!this.state.TOWN || !this.state.PRECINCT) {
      this.setState({
        severitySb: 'warning',
        messageSb: "Veuillez renseigner votre ville et quartier de résidence pour continuer",
        openSb: true
      })
      return
    }

    new EmployeesService().updateAddress({
      ...this.state
    }).then(res => {
      if (res.status === 200) {
        CookieH.setUser(this.state)
        this.setState({
          severitySb: 'success',
          messageSb: <FormattedMessage id="Successly saved"/>,
          openSb: true,
          submitteAdress: true
        })
      }
    }, (err) => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      this.setState({
        severitySb: 'error',
        messageSb: "Une erreur s'est produite lors de la tentative de sauvegarde",
        openSb: true
      })
    })
  }
  updateProfessional = () => {
    new EmployeesService().updateProfessional({...this.state}).then(res => {
      if (res.status === 200) {
        CookieH.setUser(this.state)
        this.setState({
          severitySb: 'success',
          messageSb: <FormattedMessage id="Successly saved"/>,
          openSb: true,
          submittedPro: true
        })
      }
    }, (err) => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      this.setState({
        severitySb: 'error',
        messageSb: "Une erreur s'est produite lors de la tentative de sauvegarde",
        open: true
      })
    })
  }
  updateLastDiploma = () => {
    new EmployeesService().updateLastDiploma({
      ...this.state
    }).then(res => {
      if (res.status === 200) {
        CookieH.setUser(this.state)
        this.setState({
          severitySb: 'success',
          messageSb: <FormattedMessage id="Successly saved"/>,
          openSb: true
        })
      }
    }, (err) => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      this.setState({
        severitySb: 'error',
        messageSb: "Une erreur s'est produite lors de la tentative de sauvegarde",
        openSb: true
      })
    })
  }
  loadDiploma = () => {
    new DiplomaService().getByEmployee(this.state.MATRICULE).then(r => {
      if (r.status === 200) {
        this.setState({files: r.data})
      }
    }, err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
    })
  }
  createDiploma = async (files) => {
    if (this.state.DESIGNATION && this.state.SPECIALITY && this.state.LEVEL && this.state.LANGUAGE && this.state.OBTENEDDATE && this.state.OBTENEDSTAB) {
      new DilplomaS().createDiploma({
        ...this.state,
        FILE: await blobToBase64(files[0]),
      }).then(res => {
        if (res.status === 201) {
          new DilplomaS().getAll().then(r => {
            if (r.status === 200)
              this.setState({files: r.data})
          }, err => {
            if (err?.status === 401)
              return this.tokenExpiredRedirection()
          })
          CookieH.setUser(this.state)
          this.loadDiploma()
          this.setState({
            severitySb: 'success',
            messageSb: "Le fichier a été sauvegardé avec succès",
            openSb: true
          })
        }
      }).catch(err => {
        if (err?.status === 401)
          return this.tokenExpiredRedirection()
        if (err?.status === 400)
          this.setState({
            severitySb: 'error',
            messageSb: err?.data?.messageSb === 'exists' ? "Impossible de continuer l'operation car un fichier semblable existe déjà" : err?.data?.messageSb,
            openSb: true
          })
      })
    } else {
      this.setState({
        severitySb: 'warning',
        messageSb: 'Veuillez reseigner tous les champs avant de poursuivre',
        openSb: true
      })
    }
  }
  updateFinancial = () => {
    if (this.state.ACTIVITY_PRINCIPAL !== 'officer')
      this.setState({PRESENCE_ATTEST: null, MINISTERIAL_DECREE: null})
    new EmployeesService().updateFinancial({
      ...this.state
    }).then(res => {
      if (res.status === 200) {
        CookieH.setUser({...this.state, ...res.data})
        this.setState({
          ...res.data,
          severitySb: 'success',
          messageSb: <FormattedMessage id="Successly saved"/>,
          openSb: true,
          submittedRh2: true
        })
      }
    }).catch((err) => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      this.setState({
        severitySb: 'error',
        messageSb: "Une erreur s'est produite lors de la tentative de sauvegarde",
        openSb: true
      })
    })
  }
  updatePaymentMode = () => {
    new EmployeesService().updatePaymentMode({
      ...this.state
    }).then(res => {
      CookieH.setUser({...this.state, ...res.data})
      if (res.status === 200) {
        CookieH.setUser(this.state)
        this.setState({
          severitySb: 'success',
          messageSb: <FormattedMessage id="Successly saved"/>,
          openSb: true,
          submittedRh3: true
        })
      }
    }).catch((err) => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      this.setState({
        severitySb: 'error',
        messageSb: "Une erreur s'est produite lors de la tentative de sauvegarde",
        openSb: true
      })
    })
  }
  updateIdentification = () => {
    new EmployeesService().updateIdentification({
      ...this.state
    }).then(res => {
      if (res.status === 200) {
        CookieH.setUser({...this.state, ...res.data})
        this.setState({
          ...res.data,
          severitySb: 'success',
          messageSb: <FormattedMessage id="Successly saved"/>,
          openSb: true,
          submittedRh1: true
        })
      }
    }).catch((err) => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      this.setState({
        severitySb: 'error',
        messageSb: "Une erreur s'est produite lors de la tentative de sauvegarde",
        openSb: true
      })
    })
  }
  removeDiploma = (col) => {
    new EmployeesService().removeFile(this.state[col], col, this.state.MATRICULE).then(res => {
      if (res.status === 200) {
        let data = {}
        data[col] = null
        this.setState(data)
        let newUser = {...this.state}
        delete newUser[col]
        CookieH.setUser(newUser)
      }
    }, err => {

      if (err?.status === 401)
        return this.tokenExpiredRedirection()
    })
  }
  addOrganization = (organization) => {
    return new DepartmentS().addOrga(organization).then(res => {
      if (res.status === 201) {
        this.loadOrganization()
      }
      return res
    }, err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      if (err?.status === 409)
        this.setState({
          openSb: true,
          messageSb: 'Another organizations with the same name already exists !',
          severitySb: 'error'
        })
      else
        this.setState({openSb: true, messageSb: 'Unable to execute the operation !', severitySb: 'error'})
      return err
    })
  }
  updateOrganization = (organization) => {
    return new DepartmentS().uptOrga(organization).then(res => {
      if (res.status === 200) {
        this.loadOrganization()
      }
      return res
    }, err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      if (err?.status === 409)
        this.setState({
          openSb: true,
          messageSb: 'Another organizations with the same name already exists !',
          severitySb: 'error'
        })
      else
        this.setState({openSb: true, messageSb: 'Unable to execute the operation !', severitySb: 'error'})
      return err
    })
  }
  loadOrganization = () => {
    try {
      new DepartmentS().getDepartments().then(res => {
        if (res.status === 200) {
          this.setState({organizations: res.data})
        }
      }, err => {

        if (err?.status === 401)
          return this.tokenExpiredRedirection()
      })
    } catch (e) {
      console.log(e)
    }
  }
  loadClasses = () => {
    try {
      new ClassesS().getClasses().then(res => {
        if (res.status === 200) {
          this.setState({classes: res.data || []})
        }
      }, err => {

        if (err?.status === 401)
          return this.tokenExpiredRedirection()
      })
    } catch (e) {
      console.log(e)
    }
  }
  loadAdmins = () => {
    try {
      new UserS().getUsers().then(res => {
        if (res.status === 200)
          this.setState({administrators: res.data})
      }, err => {
        if (err?.status === 401)
          return this.tokenExpiredRedirection()
      })
    } catch (e) {
      console.log(e)
    }
  }
  loadEmployees = () => {
    try {
      new EmployeeS().getEmployees().then(res => {
        if (res.status === 200) {
          this.setState({
            employees: res.data,
          })
        }
      }, err => {

        if (err?.status === 401)
          return this.tokenExpiredRedirection()
      })
    } catch (e) {
      console.log(e)
    }
  }
  loadYears = () => {
    try {
      new AcademicYearS().gets().then(res => {
        if (res.status === 200) {
          this.setState({
            years: res.data,
          })
          this.dataInitializer()
        }
      }, err => {
        if (err?.status === 401)
          return this.tokenExpiredRedirection()
      })
    } catch (e) {
      console.log(e)
    }
  }
  importEmployees = () => {
    new EmployeeS().importEmployees().then(res => {
      console.log(res)
      if (res.status === 200) {
        this.loadEmployees()
        this.setState({
          severitySb: 'success',
          employees: res.data,
          messageSb: 'Data imported successfully, wait for refresh',
          openSb: true,
        })
      }
    }, err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
    })
  }
  defineLastDiploma = (id, MATRICULE) => {
    return new EmployeesService().defineLastDiploma(MATRICULE, id).then(res => {
      if (res.status === 200) {
        this.setState({
          severitySb: 'success',
          messageSb: "Successly saved",
          openSb: true
        })
      }
      return res
    }, err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      this.setState({
        severitySb: 'error',
        messageSb: "An error has occured during saving",
      })
      return err
    })
  }
  closeAffectations = (array) => {
    return new ApplicationChoiceS().closeAffectations(array).then(res => {
      if (res.status === 200) {
        this.setState({
          severitySb: 'success',
          messageSb: "Successly saved",
          openSb: true
        })
      }
      return res
    }, err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      this.setState({
        severitySb: 'error',
        messageSb: "An error has occured during saving",
      })
      return err
    })
  }
  setClassDepartment = (department, classId) => {
    new ClassesS().setClassDepartment(department, classId).then(res => {
      if (res.status === 200) {
        this.setState({
          openSb: true,
          messageSb: 'Department updated successfully !',
          severitySb: 'success'
        })
        this.loadClasses()
      }
    }, err => {

      if (err?.status === 401)
        return this.tokenExpiredRedirection()
    })
  }
  confirmDeletionRole = (elt) => {
    return new ProfileS().deleteRole(elt).then(res => {
      if (res.status === 200) {
        this.loadRoles()
        this.loadAdmins()
      }
      return res
    }, err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      return err
    })
  }
  handleLogin = ({USERNAME, PASSWORD}) => {

    new UserS().login({USERNAME, PASSWORD}).then(res => {
      let expires = new Date()
      expires.setMonth(new Date().getMonth() + 3)
      if (res.status === 200) {

        CookieH.setUser(res.data, {
          path: '/',
          expires: this.state.remember ? expires : 0
        })
        this.setState({...res.data})

        this.props.history.push({
          pathname: '/'
        })
      } else if (res.status === 204) {
        this.setState({open: true, messageSb: "Incorrect username or password", severitySb: "error"})
      }
    }).catch(err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      this.setState({open: true, messageSb: "Unable to sign in now. Please retry later !", severitySb: "error"})
    })

  }
  contactHim = (mail, EMPLOYEES) => {
    return new EmployeeS().contactHim(mail, EMPLOYEES).then(res => {
      this.setState({
        openSb: true,
        messageSb: 'mail sent successfully',
        severitySb: 'success'
      })
      return res
    }, err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      return err
    })

  }
  markTransmitted = (batchesId) => {
    return new RequestBatchS().markTransmitted(batchesId).then(res => {
      return res
    }, err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      return err
    })

  }
  cancelBatch = (batchId) => {
    return new RequestBatchS().cancelBatch(batchId).then(res => {
      return res
    }, err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      return err
    })

  }
  affectTeacherToClass = (SUBJECT_ID, CLASS_ID, appId) => {
    return new ApplicationChoiceS().affectTeacherToClass(SUBJECT_ID, CLASS_ID, appId).then(res => {
      return res
    }, err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      return err
    })

  }
  addAdmin = ({username, profile, pwd, privilege, updating, group, orgaGroup}) => {
    if (updating)
      return new UserS().updateAdmin({
        id: updating.id,
        username,
        profile,
        pwd,
        privilege,
        group,
        orgaGroup
      }).then(res => {
        if (res.status === 200) {
          if (updating.id === CookieH.getUser().id) {
            CookieH.clearUser()
            history.push('/login')
          } else
            this.loadAdmins();
        }
        return res
      }, err => {
        if (err?.status === 401)
          return this.tokenExpiredRedirection()
        return err
      })
    else
      return new UserS().createAdmin({username, profile, pwd, privilege, group, orgaGroup}).then(res => {
        if (res.status === 201)
          this.loadAdmins();
        return res
      }, err => {
        if (err?.status === 401)
          return this.tokenExpiredRedirection()
        return err
      })
  }
  getRequestConcerned = (year = null) => {
    this.setState({requests: [], requestParsed: []})
    new RequestS().getRequestConcerned(CookieH.getUser()?.RequestGroup?.REQUEST_GROUP_ID, year).then(res => {
      if (res.status === 200)
        this.setState({requests: res.data, requestParsed: res.data}, () => {
          this.filterRequests()
        })
    }, err => {

      if (err?.status === 401)
        return this.tokenExpiredRedirection()
    })
  }
  createSessionCourses = (data) => {
    return new SessionApplicationS().create(data).then(res => {
      if (res.status === 201) {
        this.handleSidebar(false)
        this.props.setState({
          severitySb: 'success',
          messageSb: "The session was opened successfully",
        })
      }
      return res
    }, err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      else if (err.status === 409)
        this.props.setState({
          severitySb: 'error',
          messageSb: err.data.message,
        })
    })
  };
  loadIdentiTypes = () => {
    try {
      new IdTypesS().getAll().then(res => {
        if (res.status === 200)
          this.setState({identifTypes: res.data})
      }, err => {
        if (err?.status === 401)
          return this.tokenExpiredRedirection()
      })
    } catch (e) {
      console.log(e)
    }
  }
  handleSidebar = () => {
    this.setState({sidebar: !this.state.sidebar})
  }
  loadSessions = () => {
    try {
      new SessionApplicationS().gets().then(res => {
        if (res.status === 200)
          this.setState({sessions: res.data})
      }, err => {

        if (err?.status === 401)
          return this.tokenExpiredRedirection()
      })
    } catch (e) {
      console.log(e)
    }
  }
  deleteOrganization = (ORGA_GROUP_ID) => {
    return new DepartmentsService().deleteOrganization(ORGA_GROUP_ID).then(res => {
      if (res.status === 200)
        this.loadOrganization()
      return res
    }, err => {

      if (err?.status === 401)
        return this.tokenExpiredRedirection()
    })
  }

  signInWithFederate = (federate) => {
    return new UserS().signInWithFederate(federate).then(res => {
      let expires = new Date()
      expires.setMonth(new Date().getMonth() + 3)
      if (res.status === 200) {
        console.log(res.data)
        CookieH.setUser(res.data, {
          path: '/',
          expires: this.state.remember ? expires : 0
        })
        this.setState({...res.data})
        this.props.history.push({pathname: '/'})

      } else if (res.status === 204) {
        this.setState({open: true, messageSb: "Incorrect username or password", severitySb: "error"})
      }
    }).catch(err => {
      if (err?.status === 401)
        return this.tokenExpiredRedirection()
      this.setState({open: true, messageSb: "Unable to sign in now. Please retry later !", severitySb: "error"})
    })
  }
  loadBatches = () => {
    new RequestBatchS().getBatches(CookieH.getUser()?.RequestGroup?.REQUEST_GROUP_ID).then(res => {
      if (res.status === 200) {
        this.setState({batches: res.data, open: true, message: "Data load successfully", severity: "success"})
      }
    }).catch(err => {
      this.setState({openSb: true, messageSb: "Unable to load data. Please retry later", severitySb: "error"})
    })
  }

  loadRequestCategories = () => {
    new ReqCategoriesS().getCategories().then(res => {
      this.setState({requestCategories: res.data})
    })
  }


  filterRequests = (state = {
    filterTransmissible: false,
    filterStatus: "",
    filterOptionCategory: "",
    filterSchool: "",
    filterCategory: "",
    filterCampus: ""
  }) => {
    let requestParsed = this.state.requests?.filter(e => {
      let filterSchool = e?.REQUEST_UNITY_ID?.toLowerCase()?.includes(state.filterSchool?.toLowerCase() || "")
      let filterCampus;
      if ((state.filterCampus || "")?.length === 1)
        filterCampus = e?.CLASS_ID?.toLowerCase()?.split(' ')?.pop() === state.filterCampus?.toLowerCase();
      else
        filterCampus = e?.CLASS_ID?.toLowerCase()?.split(' ')?.pop()?.includes(state.filterCampus?.toLowerCase(),);
      let filterStatus = e?.REQUEST_STATUS?.toLowerCase()?.includes(state.filterStatus?.toLowerCase() || "")
      let filterOptionCategory = e.RequestCategory?.ID_CAT?.toLowerCase()?.includes(state?.filterOptionCategory?.toLowerCase() || "")
      let filterCategory = e.RequestCategory?.ID_SCAT?.toLowerCase()?.startsWith(state?.filterCategory?.toLowerCase() || "")
      let filterTransmissible = (e?.REQUEST_UNITY_TRANSMISSIBLE === state?.filterTransmissible || false)
      return filterSchool && filterStatus && filterOptionCategory && filterCategory && filterTransmissible && filterCampus
    })
    this.setState({requestParsed})
  }


  tokenExpiredRedirection = () => {
    this.setState({openDg: true})
  }

  loadLabelDecision = (unity, dest, category) => {
    this.setState({labelDeicisons: []})
    new LibeleDecisionS().getDecisions(unity, dest, category).then(res => {
      this.setState({labelDeicisons: res.data})
    }, err => {
      this.setState({
        openSb: true,
        openBd: false,
        messageSb: 'Error when trying to load label decision',
        severitySb: 'error'
      })
    })
  }

  newEvaluation = (data) => {
    this.setState({openBd: true})
    return new EvaluationSessionS().newEvaluation(data).then(res => {
      if (res.status === 200)
        this.listEvaluationsSessions()
      return res
    }, err => {
      this.setState({openSb: true, openBd: false, ...err.data, severitySb: 'error'})
    })
  }

  listEvaluationsSessions = () => {
    try {
      this.setState({openBd: true})
      return new EvaluationSessionS().listEvaluationsSessions().then(res => {
        if (res.status === 200)
          this.setState({evaluationSessions: res.data})
        return res
      }).finally(() => {
        this.setState({openBd: false})
      })
    } catch (e) {
      console.log(e)
    }
  }

  evaluationSessionsCancel = (id) => {
    this.setState({openBd: true})
    return new EvaluationSessionS().cancel(id).then(res => {
      if (res.status === 200)
        this.listEvaluationsSessions()
      return res
    }).finally(() => {
      this.setState({openBd: false})
    })
  }
  listEvaluations = () => {
    try {
      this.setState({openBd: true})
      return new EvaluationS().listEvaluations().then(res => {
        if (res.status === 200)
          this.setState({evaluations: res.data})
        return res
      }).finally(() => {
        this.setState({openBd: false})
      })
    } catch (e) {
      console.log(e)
    }
  }
  listOfSubjects = () => {
    try {
      return new SubjectsS().listOfSubjects().then(res => {
        if (res.status === 200)
          this.setState({subjects: res.data})
        return res
      }).finally(() => {
      })
    } catch (e) {
      console.log(e)
    }
  }

  saveEvaluationForm = (data) => {
    return new EvaluationFormS().saveEvaluationForm(data).then(res => {
      if (res.status === 201)
        this.listEvaluationForm()
      return res
    }).finally(() => {
    })
  }
  updateEvaluationForm = (data) => {
    return new EvaluationFormS().updateEvaluationForm(data).then(res => {
      if (res.status === 200)
        this.listEvaluationForm()
      return res
    })
  }
  evaluationFormDelete = (id) => {
    return new EvaluationFormS().evaluationFormDelete(id).then(res => {
      if (res.status === 200)
        this.listEvaluationForm()
      return res
    }).finally(() => {
    })
  }

  evaluationFormDuplicate = (id) => {
    return new EvaluationFormS().evaluationFormDuplicate(id)
  }
  listEvaluationForm = () => {
    try {
      return new EvaluationFormS().listEvaluationForm().then(res => {
        if (res.status === 200)
          this.setState({evaluationForms: res.data})
        return res
      }).finally(() => {
      })
    } catch (e) {
      console.log(e)
    }
  }

  loadQuestions = (form) => {
    return new QuestionFormS().loadQuestions(form).then(res => {
      return res
    }).finally(() => {
    })
  }
  questionFormDelete = (id) => {
    return new QuestionFormS().questionFormDelete(id).then(res => {
      return res
    }).finally(() => {
    })
  }

  newQuestion = (data) => {
    return new QuestionFormS().newQuestion(data)
  }

  autoValidate = (limiter) => {
    return new ApplicationChoiceS().autoValidate(limiter).then(res => {
      if (res.status === 200)
        this.setState({
          openSb: true,
          messageSb: 'Auto validation has been done for ' + res.data[0] + ' element(s)',
          severitySb: 'success'
        })
    }).finally(() => {
    })
  }


  dataInitializer = () => {
    this.loadEmployees()
    this.loadBatches()
    if (CookieH.hasPrivilege(2)) {
      this.getRequestConcerned()
      this.loadRequestGroups()
      this.loadRequestCategories()
    }
    if (CookieH.hasPrivilege(3))
      this.loadSessions()
    if (CookieH.hasPrivilege(4)) {
      this.loadAdmins()
      this.loadIdentiTypes()
    }
    if (CookieH.hasPrivilege(5))
      this.loadOrganization()
    if (CookieH.hasPrivilege(6))
      this.loadRoles()
    if (CookieH.hasPrivilege(7)) {
      this.listEvaluationsSessions()
      this.listOfSubjects()
      this.listEvaluations()
      this.listEvaluationForm()
    }
    this.setState({openBd: true})
    if (CookieH.hasPrivilege(8))
      this.loadClasses()

    this.ttlAct()
  }

  ttlAct = () => {
    let time = CookieC.TTL

    const fn = () => {
      time--
      if (time <= 0) {
        if (CookieH.getUser()) {
          clearInterval(timer)
          this.setState({openDg: true})
        }
      }
    }


    let timer = setInterval(fn, 1000)

    window.onclick = () => {
      clearInterval(timer)
      time = CookieC.TTL
      timer = setInterval(fn, 1000)
    }
  }

  report = (config) => {
    this.setState({requestReport: [], openBd: true})
    new RequestS().getReport(config).then(res => {
      if (res.status === 201) {
        this.setState({requestReport: res.data, openBd: false})
      }
    })
  }

  async componentDidMount() {
    if (CookieH.getUser()) {
      this.loadYears();
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.id !== prevState.id && this.state.id) {
      this.loadYears();
    }
  }


  render() {
    return (
      <Context.Provider
        value={{
          state: this.state,
          setState: (e) => this.setState(e),
          loadRole: this.loadRoles,
          loadAdmins: this.loadAdmins,
          addAdmin: this.addAdmin,
          addRole: this.addRole,
          confirmDeletionRole: this.confirmDeletionRole,
          handleLogin: this.handleLogin,
          loadEmployees: this.loadEmployees,
          importEmployees: this.importEmployees,
          loadOrganization: this.loadOrganization,
          loadClasses: this.loadClasses,
          contactHim: this.contactHim,
          getRequestConcerned: this.getRequestConcerned,
          removeDiploma: this.removeDiploma,
          loadYears: this.loadYears,
          defineLastDiploma: this.defineLastDiploma,
          updateOrganization: this.updateOrganization,
          addOrganization: this.addOrganization,
          setClassDepartment: this.setClassDepartment,
          closeAffectation: this.closeAffectations,
          loadSessionApplications: this.loadSessionApplications,
          loadSessionApplicationsFilter: this.loadSessionApplicationsFilter,
          loadSessionSubjectChoiceForClass: this.loadSessionSubjectChoiceForClass,
          loadSessionSubjectChoiceForClass2: this.loadSessionSubjectChoiceForClass2,
          createSessionCourses: this.createSessionCourses,
          handleSidebar: this.handleSidebar,
          loadSessions: this.loadSessions,
          deleteOrganization: this.deleteOrganization,
          signInWithFederate: this.signInWithFederate,
          markTransmitted: this.markTransmitted,
          cancelBatch: this.cancelBatch,
          loadBatches: this.loadBatches,
          affectTeacherToClass: this.affectTeacherToClass,
          filterRequests: this.filterRequests,
          report: this.report,
          loadLabelDecision: this.loadLabelDecision,
          newEvaluation: this.newEvaluation,
          listEvaluationsSessions: this.listEvaluationsSessions,
          evaluationSessionsCancel: this.evaluationSessionsCancel,
          listEvaluations: this.listEvaluations,
          autoValidate: this.autoValidate,
          saveEvaluationForm: this.saveEvaluationForm,
          updateEvaluationForm: this.updateEvaluationForm,
          evaluationFormDuplicate: this.evaluationFormDuplicate,
          evaluationFormDelete: this.evaluationFormDelete,
          listEvaluationForm: this.listEvaluationForm,
          loadQuestions: this.loadQuestions,
          questionFormDelete: this.questionFormDelete,
          newQuestion: this.newQuestion,
        }}
      >
        <Backdrop
          sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
          open={this.state.openBd || false}
          onClick={() => this.setState({openBd: false})}
        >
          <CircularProgress color="inherit"/>
        </Backdrop>
        <MsgDialog msgOk={this.state?.msgOk} handleOk={this.state.handleOk} open={this.state.msgOpen||false}
                   msgTitle={this.state.msgTitle} msgDesc={this.state.msgDesc}
                   handleNo={this.state.handleNo} handleYes={this.state.handleYes}/>
        <MySnackbar
          open={this.state.openSb}
          message={this.state.messageSb}
          severity={this.state.severitySb}
          setClose={() => this.setState({openSb: false})}
        />
        <SessionExpired open={this.state.openDg} handleClose={() => {
          this.setState({openDg: false})
          CookieH.clearUser()
          window.location = '/login'
        }}/>
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default withRouter(Global)
export {Context as GlobalContext}
