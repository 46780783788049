import axios from 'axios'
import CookieH from "../helpers/cookie.h";
import CookieC from "../constants/cookie.c";

export default class DataS {

  constructor() {
    this.client = axios.create({
      baseURL: `${CookieC.SERVER}`,
      headers: {
        'content-type': 'application/json',
      }
    })

    this.client.interceptors.response.use(res => {
      return res;
    }, error => {
      let reject;
      try {
        reject = error.response
      } catch (e) {
        reject = {message: 'Error of connection'}
      }
      return Promise.reject(reject);
    });
  }


  post = (url, data, config) => {
    let params = {...config}
    if (CookieH.getUser()?.TOKEN)
      params['Authorization'] = `Bearer ${CookieH.getUser().TOKEN}`

    return this.client.post(url, data, {
      headers: params
    })
  }

  patch = (url, data, config) => {
    let params = {...config}
    if (CookieH.getUser()?.TOKEN)
      params['Authorization'] = `Bearer ${CookieH.getUser().TOKEN}`

    return this.client.patch(url, data, {
      headers: params
    })
  }

  get = (url, config) => {
    let params = {...config}
    if (CookieH.getUser()?.TOKEN)
      params['Authorization'] = `Bearer ${CookieH.getUser().TOKEN}`

    return this.client.get(url, {
      headers: params
    })
  }
}
