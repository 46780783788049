import DataS from "./data.s";

export default class ClassesS extends DataS {
  getSchoolList() {
    return this.get('/classes/school')
  }

  getSchoolClass(id) {
    return this.get('/classes/school/' + id)
  }

  setClassDepartment(department, classId) {
    return this.patch('/classes/set-department', {department, classId})
  }

  getClasses() {
    return this.get('/classes')
  }
}
