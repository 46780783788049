import DataS from "./data.s";
import CookieH from "../helpers/cookie.h";

export default class ApplicationChoiceS extends DataS {
  
  affect(data) {
    return this.post('/applications-choices/affect', data)
  }
  
  createOrUpdate(data) {
    return this.post('/applications-choices', data)
  }
  
  closeAffectations(data) {
    return this.post('/applications-choices/close', {data})
  }
  
  getTeacherChoices(appId, orgaId) {
    return this.get('/applications-choices/' + appId + '/' + orgaId)
  }
  
  getTeacherForSubject(appId, sessionId, orgaId) {
    return this.get('/applications-choices/teachers/' + appId + '/' + sessionId + '/' + orgaId)
  }
  
  getAppChoiceForSubject(organization) {
    return this.post('/applications-choices/by-class', {organization})
  }
  
  getAppChoiceForSubject2(organization) {
    return this.post('/applications-choices/by-class-2', {organization})
  }
  
  getAffectationList() {
    return this.get('/applications-choices/list-all')
  }
  
  autoValidate(delimiter) {
    return this.post('/applications-choices/predicated', {delimiter, group: CookieH.getUser().OrganizationGroup.ORGA_GROUP_ID})
  }
  
  affectTeacherToClass(SUBJECT_ID, CLASS_ID, appId) {
    return this.post('/applications-choices/affect-to-class', {SUBJECT_ID, CLASS_ID, appId})
  }
}
