import DataS from "./data.s";

export default class ApplicationS extends DataS {

  create(data) {
    return this.post('/applications', data)
  }

  init(EMPLOYEE_ID, TOTAL_HOURS, SESSION_APPLICATION_ID, SUBJECT_CONSTRAINT_ID) {
    return this.post('/applications/init', {
      EMPLOYEE_ID,
      TOTAL_HOURS,
      SESSION_APPLICATION_ID,
      SUBJECT_CONSTRAINT_ID
    })
  }

  current(MATRICULE, SESSION_APPLICATION_ID) {
    return this.get('/applications/current/' + MATRICULE + '/' + SESSION_APPLICATION_ID)
  }

  gets(MATRICULE) {
    return this.get('/applications/' + MATRICULE)
  }

  getForSession(id) {
    return this.get('/applications/session/' + id)
  }
  getForSessionAffectionList(id) {
    return this.get('/applications/session/filter/' + id)
  }
}
