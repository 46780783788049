import DataS from "./data.s";

export default class DepartmentS extends DataS {
  getDepartments() {
    return this.get('/organizations')
  }

  addOrga(organization) {
    return this.post('/organizations', organization)
  }

  uptOrga(organization) {
    return this.patch('/organizations', organization)
  }

  deleteOrganization(ORGA_GROUP_ID) {
    return this.get('/organizations/delete/' + ORGA_GROUP_ID)
  }
}
