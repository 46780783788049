import DataS from "./data.s";

export default class QuestionFormS extends DataS {
    loadQuestions(form) {
        return this.get('/question-forms/' + form)
    }

    questionFormDelete(id) {
        return this.get('/question-forms/delete/' + id)
    }

    stats(id) {
        return this.get('/evaluation-forms/stats/' + id)
    }

    newQuestion(data) {
        return this.post('/question-forms', data)
    }
}
