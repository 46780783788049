import DataS from "./data.s";

export default class EvaluationSessionS extends DataS {
  newEvaluation(data) {
    return this.post('/evaluation-sessions', data)
  }

  listEvaluationsSessions() {
    return this.get('/evaluation-sessions')
  }

  cancel(id) {
    return this.get('/evaluation-sessions/delete/' + id)
  }
}
