import CookieC from "../constants/cookie.c";
import Cookies from 'universal-cookie'

export default class CookieH {
  static getUser = () => {
    return new Cookies().get(CookieC.FP_USER)
  }

  static setUser = (data, config) => {
    new Cookies().set(CookieC.FP_USER, data, config)
  }

  static getUserPrivilege = () => {
    return this.getUser().USERPRIVILEGE.split('-').map(pri => parseInt(pri))
  }

  static hasPrivilege = (privilege) => {
    return CookieH.getUserPrivilege().includes(privilege)
  }

  static clearUser = () => {
    return new Cookies().remove(CookieC.FP_USER)
  }
  
  
  static setVersion = () => {
    new Cookies().set(CookieC.FP_VERSION, CookieC.VERSION)
  }
  
  static getVersion = () => {
    return new Cookies().get(CookieC.FP_VERSION)
  }
}
