import React from "react";
import {Alert as MuiAlert, Snackbar} from "@mui/material";

const MySnackbar = (props) => {
  return (
    <Snackbar open={props.open||false} autoHideDuration={6000} onClose={props.setClose}>
      <MuiAlert elevation={6} variant="filled" {...props} onClose={props.setClose} severity={props.severity}>
        {props.message} !
      </MuiAlert>
    </Snackbar>
  )
  
}

export default MySnackbar;