import DataS from "./data.s";

export default class ProfileS extends DataS {
    getProfiles() {
        return this.get('/profiles')
    }

    addRole(data){
        return this.post('/profiles', data)
    }

    updateRole(data){
        return this.post('/profiles/update', data)
    }
    
    deleteRole(elt) {
        return this.get('/profiles/delete/' + elt)
    }
}
