import React from "react"
import {Navbar} from "reactstrap"
import classnames from "classnames"
import NavbarUser from "./NavbarUser"

const ThemeNavbar = props => {
  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        style={{marginTop: '2.2rem'}}
        className={classnames(
          "header-navbar navbar-expand-lg rounded navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": true,
            "fixed-top": true,
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-end align-items-center"
              id="navbar-mobile"
            >
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"/>
                  <h2 className="text-primary brand-text mb-0">GALIO</h2>
                </div>
              ) : null}
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userImg={''}
                loggedInWith={
                  props.user !== undefined &&
                  props.user.login.values !== undefined
                    ? props.user.login.values.loggedInWith
                    : null
                }
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}

export default ThemeNavbar
